import "./App.css";
import Maintenance from "./components/Maintenance";
import React, { useState } from 'react';
import ContatoForm from "./components/Email";
import PageFooter from "./modules/Footer";
import PageHeader from "./modules/Header";
import Counter from './components/Counter';
/*import ContatoForm from "./components/Email";
import LoginPage from "./components/auth";
import ObservableKeyboard from "./components/ObservableKeyboard";
import { Carousel, Layout } from "antd";
import GameList from "./components/GameList";
import TopMenu from "./components/menu";*/
import sing from './components/Singleton';

const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};


function App() {
  
  return (
    <>
      {/*sing.imprimir()*/}
      <Counter />
      <PageHeader />
      <Maintenance/>
      
      <PageFooter />
    </>
  );
}

/*
<Counter />
      <div>
        <p>Nome: <input type="text" value={values.field1} onChange={(event) => handleInputChange("field1", event.target.value)} /></p>
        <p>Email: <input type="email" value={values.field2} onChange={(event) => handleInputChange("field2", event.target.value)} /></p>
        <ObservableKeyboard setKeyPressed={(value) => handleInputChange("field1", value)} />
        <ObservableKeyboard setKeyPressed={(value) => handleInputChange("field2", value)} />
        <ContatoForm />
      </div>

*/
export default App;

/*
<TopMenu />
      <Layout
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          width: "80%",
          margin: "15px auto 0 auto ",
        }}
      >
        <Carousel autoplay>
          <div>
            <h3 style={contentStyle}>1</h3>
          </div>
          <div>
            <h3 style={contentStyle}>2</h3>
          </div>
          <div>
            <h3 style={contentStyle}>3</h3>
          </div>
          <div>
            <h3 style={contentStyle}>4</h3>
          </div>
        </Carousel>
        <GameList />
      </Layout>
**/
