class Singleton
{
    static instance;

    constructor()
    {
        if (Singleton.instance)
        {
            return Singleton.instance;
        }
        Singleton.instance = this;
    }

    imprimir()
    {
        return (
            <div>
                <p>Agora estou inserindo um valor de um singleton.</p>
            </div>
        );
    }
}

const instance = new Singleton();
Object.freeze(instance);
export default instance;