import { Layout, Typography } from "antd";

const { Footer } = Layout;
const { Paragraph } = Typography;

const PageFooter = () => {
  return (
    <Footer
      style={{
        position: "fixed",
        left: 0,
        bottom: 0,
        width: "100%",
        backgroundColor: "#333",
        color: "#fff",
        textAlign: "center",
      }}
    >
      <Paragraph style={{ color: "white" }}>
        &copy; 2023-2024 Garotada Games. Todos os direitos reservados.
      </Paragraph>
    </Footer>
  );
};

export default PageFooter;
