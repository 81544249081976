import React from "react";
import { Layout, Typography, Row, Col, Card } from "antd";
import frameimg from "./../resources/images/underLogo.png";

const { Header, Footer, Content } = Layout;
const { Title, Paragraph } = Typography;


const Maintenance = () => {
  return (
    <Layout style={{ minHeight: "90vh" }}>
      <Content style={{ padding: "50px" }}>
        <Row gutter={[16, 16]} justify="center">
          <Col xs={24} sm={12} lg={8}>
            <Card
              cover={<img alt="Em desenvi" src={frameimg} />}
              bordered={false}
            >
              <Title level={1} style={{ textAlign: "center" }}>
                Em desenvolvimento!
                <br />
              </Title>
              <Paragraph style={{ textAlign: "center" }}>
                Nossa página está em desenvolvimento, mas prometemos que valerá
                a espera. Estamos trabalhando duro para que possa desfrutar de
                jogos bem divertidos!
              </Paragraph>
              <Paragraph style={{ textAlign: "center" }}>
                contato@garotadagames.com.br
              </Paragraph>
            </Card>
          </Col>
        </Row>

      </Content>
    </Layout>
  );
};

export default Maintenance;
