import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue, set, off, runTransaction} from '@firebase/database';

const firebaseConfig = {
  apiKey: "AIzaSyA5nvRx9OE3IJTmK30JzUQpt4W5DGMTJYs",
  authDomain: "garotadagames-54.firebaseapp.com",
  databaseURL: "https://garotadagames-54-default-rtdb.firebaseio.com",
  projectId: "garotadagames-54",
  storageBucket: "garotadagames-54.appspot.com",
  messagingSenderId: "868949484388",
  appId: "1:868949484388:web:c0821b7d392ba63d659071",
  measurementId: "G-8NJD10FWFC",
  
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

const Counter = () => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    const counterRef = ref(database, 'acessos/counter');
    onValue(counterRef, (snapshot) => {
      const value = snapshot.val();
      setCount(value);
    });

    const incrementCounter = () => {
      runTransaction(ref(database, 'acessos/counter'), (currentCount) => {
        return (currentCount || 0) + 1;
      });
    };

    incrementCounter();

    return () => {
      off(counterRef);
    };
  }, []);

  /*return (
    <div>
      <h1>Contador: {count}</h1>
    </div>
  );*/
};


export default Counter;

